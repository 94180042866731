/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <Column className="pb--80 pt--80" name={"informace"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--300 pt--50" content={"Fotogalerie"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--80 pt--20" name={"fotogalerie"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 pb--40" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=1400x_.jpg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=860x_.png"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=1400x_.png 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/97f8b640f08446eeb0dc716f09ad00ea_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/5ac68837a7b54d8ba0ec5f43ce45ecb8_s=1400x_.jpg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/e1baa38ef4054abb8dc22cc7bce85d4c_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/07f05b48443c4b189bd81344c1c9cf92_s=1400x_.jpg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=860x_.png"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=860x_.png 860w, https://cdn.swbpg.com/t/11212/7976b453766644afa608b960136b83b5_s=1400x_.png 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-62lsa9 --center bg--top pb--80 pt--60" name={"paticka"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/3c8af2ee59a845ee959e8cedb318e054_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box" content={"<span style='color: white'>Těšíme se na Vás!</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style='color: white'><a href=\"https://saywebpage.com\">Tvorba web stránek</a></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}